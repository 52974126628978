import "../../../qelpcare-call-center-application/src/main.scss";
import "./defaults.scss";
import "./claro-br.scss";

import { QelpCare } from "../../../qelpcare-call-center-application/src/QelpCare";
import { ItemByFilter } from "../../../qelpcare-call-center-application/src/widgets/ItemByFilter";
import { async } from "regenerator-runtime";
import { Accordion } from "../../../qelpcare-call-center-application/src/widgets/Accordion";
import { Tabs } from "../../../qelpcare-call-center-application/src/widgets/Tabs";
import { DeviceImage } from "../../../qelpcare-call-center-application/src/widgets/DeviceImage";
import { Qlickr } from "../../../qelpcare-call-center-application/src/widgets/Qlickr";
import { CopyURI } from "../../../qelpcare-call-center-application/src/widgets/CopyURI";
import { SMSSender } from "../../../qelpcare-call-center-application/src/widgets/SMSSender";
import { WhatsAppInterface } from "../../../qelpcare-call-center-application/src/widgets/WhatsAppInterface";
import { SendMail } from "../../../qelpcare-call-center-application/src/widgets/SendMail";
import { StepsNavigation } from "../../../qelpcare-call-center-application/src/widgets/StepsNavigation";
import { SimpleNavigate } from "../../../qelpcare-call-center-application/src/widgets/SimpleNavigate";
import { MostViewedUsecases } from "../../../qelpcare-call-center-application/src/widgets/MostViewedUsecases";
import { Faq } from "../../../qelpcare-call-center-application/src/widgets/Faq";

var Handlebars = require("handlebars/runtime");
require("./utilities.js");

class App extends QelpCare {
  constructor() {
    super(".application");

    this.pages.os_tutorial = async (app_path, tutorial) => {
      console.log("oss_tutorial");
      let app = await this.getSoftware(app_path);
      let os = app.os[0];
      let topics = await this.call(`topics/software/${app.id}`);
      app.deviceImage = os.osImage;
      console.log(app);

      let topic = topics.find((item) => {
        return item.topicPath == tutorial && item.os.path == os.path;
      });
      console.log(topic);
      let usecase = await this.call(`topics/${topic.id}`);

      this.html.head(
        this.translate("html/title") + `- ${topic.topicName} - ${app.name}`
      );
      this.render(
        this.settings.templates.pages.usecase({
          css_class: "oss_tutorial",
          steps_navigation: this.settings.templates.widgets.steps_navigation(),
          share_topic: this.settings.templates.widgets.share_topic(),
          usecase: usecase,
          os: os,
          app: app,
          device: app,
          breadcrumbs: this.breadcrumbs([
            {
              name: this.translate("breadcrumbs/home"),
              path: "/",
            },
            {
              name: app.name,
              path: `/${this.settings.routes.oss}/${app.path}`,
            },
            {
              name: usecase.topicName,
            },
          ]),
        }),
        () => {
          this.initiate.breadcrumbs();
          for (let i = 0; i < usecase.steps.length; i++) {
            let step = usecase.steps[i];
            let image_selector = "usecase-step-image-" + i;
            let step_selector = ".usecase-step.step-" + i;
            this.widgets[image_selector] = new DeviceImage(image_selector);
            if (step.animation) {
              this.widgets["qlicker" + i] = new Qlickr(
                step_selector,
                image_selector
              );
            }
          }

          this.widgets.register('send_sms_form', new SMSSender("send-sms-form"));
          this.widgets.register('copy_link', new CopyURI("copy-link"));
          this.widgets.register('whatsapp_interface', new WhatsAppInterface("whatsapp-interface"));
          this.widgets.register('send_email', new SendMail("send-email"));
          this.widgets.register('steps_navigation', new StepsNavigation("topic-usecase", "steps-navigation", usecase));
          this.widgets.register('share_topic', new Tabs("share-topic"));
          
          let aside = document.querySelector('.aside-content');
          let btn_modal = document.querySelector('.send-tutorial');
          let btn_back = document.querySelector('.share-topic-back');


          let back_to_top = document.querySelector(".back-to-top");

          back_to_top.addEventListener("click", e=>{
              window.scrollTo({
                  top:0,
                  behavior: 'smooth'
              })
          })

          if(btn_modal){
              btn_modal?.addEventListener("click", e => {
                  aside.style.display = 'block';
              });
              btn_back?.addEventListener("click", e => {
                  aside.style.display = 'none';
              });
          }
        }
      );
    };

    this.pages.home = async () => {
      let content = {};
      let aside = {};
      let side_index_itens = {};
      let manufacturers = await this.call("manufacturers");

      for (let element of this.settings.contents.home.main_content) {
        try {
          if (element.endpoint) {
            let result;
            if (typeof element.endpoint === "function") {
              result = await element.endpoint();
            } else {
              result = await this.call(element.endpoint);
            }
            if (element.widget === "apps") {
              result = result.filter((item) => {
                return !item.type[0]?.name?.match(/smartphone/gi);
              });
            }
            element.content = Object.assign(result, element?.content || {});
            element.assets = this.settings.assets;
          }
          if (element.widget == "most_viewed_devices") {
            element.content.forEach((item) => {
              item.manufacturerName = manufacturers.find((fitem) => {
                return fitem.path == item.manufacturer;
              }).name;
            });
          }
          let html = this.settings.templates.widgets[element.widget](element);
          content[element.widget] = {
            html: html,
          };
        } catch (error) {
          console.warn(error);
        }
        side_index_itens[element.widget] = element;
      }
      for (let element of this.settings.contents.home.aside_panel) {
        try {
          if (element.endpoint) {
            let result = await this.call(element.endpoint);
            element.content = result;
            element.assets = this.settings.assets;
          }
          let html = this.settings.templates.widgets[element.widget](element);
          aside[element.widget] = {
            html: html,
          };
        } catch (error) {
          console.warn(error);
        }
      }
      this.html.head(this.translate("html/title"));
      this.render(
        this.settings.templates.pages.home({
          side_index: this.settings.templates.widgets.side_index({
            list: side_index_itens,
          }),
          content: content,
          aside_panel: aside,
        }),
        () => {
          this.initiate.side_index();
          this.widgets.register(
            "manufacturers",
            new SimpleNavigate("manufacturers")
          );
          this.widgets.register(
            "most_viewed_devices",
            new SimpleNavigate("most_viewed_devices")
          );
          this.widgets.register(
            "most_viewed_usecases",
            new MostViewedUsecases("most_viewed_usecases")
          );
          this.widgets.register("categories", new SimpleNavigate("categories"));
          this.widgets.register(
            "device_types",
            new SimpleNavigate("device_types")
          );
          this.widgets.register(
            "device_groups",
            new SimpleNavigate("device_groups")
          );
          this.widgets.register("apps", new SimpleNavigate("apps"));
          this.widgets.register("faq", new Faq("faq"));

          let emulator_btn = this.element.querySelector(".device-emulator-btn");
          if (emulator_btn) {
            emulator_btn.addEventListener("click", (e) => {
              this.router.navigateTo("emulator");
            });
          }
        }
      );
    };

    this.pages.sistema_operacional = async (app_path) => {
      let app = await this.getSoftware(app_path);
      let topics = await this.call(`topics/software/os/${app.id}/${app.os[0]?.id}`);
      let categories = await this.groupByCategory(topics);
      let topic_by_os = this.settings.templates.widgets.categories_tutorials_oss({
        name: "Tutoriais",
        os: app.os,
        app: app,
        categories: categories,
      });

      let top_usecases = await this.call(`topics/popular/software/${app.id}`);

      this.html.head(this.translate("html/title") + ` - ${app.name}`);

      this.render(
        this.settings.templates.pages.os({
          app: app,
          topic_by_os: topic_by_os,
          most_viewed_usecases:
            this.settings.templates.widgets.most_viewed_usecases_oss({
              content: top_usecases,
            }),
          breadcrumbs: this.breadcrumbs([
            {
              name: this.translate("breadcrumbs/home"),
              path: "/",
            },
            {
              name: app.name,
            },
          ]),
        }),
        () => {
          this.initiate.breadcrumbs();
          this.widgets.register('most_viewed_usecases', new MostViewedUsecases('most_viewed_usecases'));
          this.widgets.register('categories_tutorials_app', new Accordion(`categories-tutorials-app`));
        }
      );
    };

    this.pages.device_group = async (group) => {
      let groups = await this.call(`devicegroups`);
      let top_devices = await this.call(`devices/popular/group/${group}`);
      let devices = await this.call(`devices/group/${group}`);
      let brands = await this.call(`manufacturers`);

      devices.forEach((item) => {
        item.manufacturerName = brands.find((fitem) => {
          return fitem.path == item.manufacturer;
        }).name;
      });
      top_devices.forEach((item) => {
        item.manufacturerName = brands.find((fitem) => {
          return fitem.path == item.manufacturer;
        }).name;
      });
      let device_group = groups.find((item) => {
        return item.path == group;
      });

      let brands_group = [];
      brands.map((item) => {
        const device = devices.find((fitem) => {
          return item.path == fitem.manufacturer;
        });
        if (device) {
          brands_group.push(item);
        }
      });

      this.html.head(this.translate("html/title") + ` - ${device_group.name}`);

      if (device_group.path == "equipamentos-de-tv") {
        this.render(
          this.settings.templates.pages.claro_tv({
            most_viewed_devices:
              this.settings.templates.widgets.most_viewed_devices({
                content: top_devices,
              }),

            specific_device: this.settings.templates.widgets.specific_device({
              title: this.translate("page/device_group/decoders"),
              description: this.translate(
                "page/device_group/filter_by_manufacturer"
              ),
              devices: devices,
              assets: this.settings.assets,
              brands: brands_group,
            }),
            devices_by_brand: this.settings.templates.widgets.devices_by_brand({
              title: this.translate("page/device_group/decoders"),
              description: this.translate(
                "page/device_group/filter_by_manufacturer"
              ),
              devices: devices,
              assets: this.settings.assets,
              brands: brands_group,
            }),
            breadcrumbs: this.breadcrumbs([
              {
                name: this.translate("breadcrumbs/home"),
                path: "/",
              },
              {
                name: device_group.name,
              },
            ]),
          }),
          () => {
            this.initiate.breadcrumbs();
            this.widgets.register(
              "devices_by_brand",
              new ItemByFilter("devices-by-brand:not(.specific_device)")
            );
            this.widgets.register(
              "specific_device",
              new ItemByFilter("specific_device")
            );
          }
        );
      } else {
        this.render(
          this.settings.templates.pages.device_group({
            most_viewed_devices:
              this.settings.templates.widgets.most_viewed_devices({
                content: top_devices,
              }),
            devices_by_brand: this.settings.templates.widgets.devices_by_brand({
              title: device_group.name,
              description: "Filtre por fabricante",
              devices: devices,
              assets: this.settings.assets,
              brands: brands_group,
            }),
            breadcrumbs: this.breadcrumbs([
              {
                name: this.translate("breadcrumbs/home"),
                path: "/",
              },
              {
                name: device_group.name,
              },
            ]),
          }),
          () => {
            this.initiate.breadcrumbs();
            this.widgets.register(
              "devices_by_brand",
              new ItemByFilter("devices-by-brand")
            );
          }
        );
      }
    };

    this.pages.softwares = async () => {
      let top_apps = await this.call("softwares/popular");
      let softwares = await this.call("softwares");

      let os_list = [];
      let oss = [];

      top_apps = top_apps.filter((item) => {
        return !item.type[0]?.name?.match(/smartphone/gi) && item.path!='interface-de-tv';
      });

      softwares = softwares.filter((item) => {
        return !item.type[0]?.path?.match(/smartphone/gi) && item.path!='interface-de-tv';
      });

      softwares.forEach((item) => {
        if (item.os) {
          item.os.forEach((os) => {
            if (!os_list[os.path]) {
              os_list[os.path] = os;
            }
          });
        }
      });

      for (let os in os_list) {
        oss.push(os_list[os]);
      }

      this.html.head(
        this.translate("html/title") +
          " - " +
          this.translate("page_title/applications")
      );

      this.render(
        this.settings.templates.pages.softwares({
          most_viewed_apps: this.settings.templates.widgets.most_viewed_apps({
            top_apps: top_apps,
          }),
          softwares: softwares,
          software_by_os: this.settings.templates.widgets.software_by_os({
            title: this.translate("page/softwares/title"),
            oss: oss,
            softwares: softwares,
          }),
          breadcrumbs: this.breadcrumbs([
            {
              name: this.translate("breadcrumbs/home"),
              path: "/",
            },
            {
              name: this.translate("breadcrumbs/applications"),
            },
          ]),
        }),
        () => {
          this.initiate.breadcrumbs();
          this.widgets.register(
            "most_viewed_apps",
            new SimpleNavigate("most_viewed_apps")
          );
          this.widgets.register(
            "software_by_os",
            new ItemByFilter("software-by-os")
          );
          this.widgets.software_by_os.slide_element = true;
        }
      );
    };
  }
}
const app = new App();


// configuraraparelhos.claro.com.br = https://api-claro-br.qelplatam.com/api-csr/
// https://d11yedpx79.execute-api.sa-east-1.amazonaws.com/dev
// https://7ex6sahy96.execute-api.sa-east-1.amazonaws.com/accept
// https://afif1fhp11.execute-api.sa-east-1.amazonaws.com/prod

app.config({
  name: "Claro Brasil - CSR",
  assets: "https://assets.qelplatam.com/",
  locale: "pt-br",
  localization: require("./assets/json/localization.json"),
  environment: {
    live: "https://configuraraparelhos.claro.com.br/",
  },
  api: [
    {
      test: /csr-claro-br-accept.qelplatam.com/,
      set: "https://7ex6sahy96.execute-api.sa-east-1.amazonaws.com/accept",
    },
    {
      test: /localhost:9015/,
      set: "https://7ex6sahy96.execute-api.sa-east-1.amazonaws.com/accept",
    },
    {
      test: /claro-br.local/,
      set: "https://afif1fhp11.execute-api.sa-east-1.amazonaws.com/prod",
    },
    {
      test: /configuraraparelhos.claro.com.br/,
      set: "https://afif1fhp11.execute-api.sa-east-1.amazonaws.com/prod",
    },
    {
      test: /claro-br-staging.qelplatam.com/,
      set: "https://afif1fhp11.execute-api.sa-east-1.amazonaws.com/prod",
    },
  ],
  root: [
    { test: /localhost:9015/, set: "//localhost:9015/" },
    {
      test: /csr-claro-br-accept.qelplatam.com/,
      set: "//csr-claro-br-accept.qelplatam.com/",
    },
    { test: /claro-br.local/, set: "/acheaqui/" },
    { test: /configuraraparelhos.claro.com.br/, set: "/acheaqui/" },
    { test: /claro-br-staging.qelplatam.com/, set: "/acheaqui/" },
  ],
  routes: {
    application: "aplicativos",
    categories: "recursos",
    type: "tipo",
    // group: "tipos-de-aparelho",
    manufacturers: "fabricantes",
    oss: "sistema-operacional",
  },
  wrappers: [
    "smartphone",
    "smartwatch",
    "tablet",
    "fixed-phone",
    "telefone-fixo",
  ],
  smssender: {
    endpoint: "https://afif1fhp11.execute-api.sa-east-1.amazonaws.com/prod/api-csr/send/sms",
  },
  whatsapp: {
    endpoint: "https://api-claro-br.qelplatam.com/api-csr/send/whatsapp",
  },
  sendmail: {
    endpoint: "https://api-claro-br.qelplatam.com/api-csr/send/email",
  },
  contents: {
    home: {
      main_content: [
        {
          name: "Aparelhos mais vistos",
          widget: "most_viewed_devices",
          endpoint: "devices/popular",
        },
        {
          name: "Tipos de aparelhos",
          widget: "device_types",
          endpoint: "devicetypes",
        },
        {
          name: "Fabricantes",
          widget: "manufacturers",
          endpoint: "manufacturers",
        },
        {
          name: "Recursos",
          widget: "categories",
          endpoint: "categories/popular",
        },
        {
          name: "Aplicativos",
          widget: "apps",
          endpoint: "softwares/popular",
        },
        {
          name: "Sistema Operacional",
          widget: "sistemas_operacionais",
          endpoint: "softwares",
        },
      ],
      aside_panel: [
        {
          name: "Tópicos mais vistos",
          widget: "most_viewed_usecases",
          endpoint: "topics/popular",
        },
      ],
    },
  },
  templates: {
    pages: {
      home: require("../../../qelpcare-call-center-application/src/templates/pages/home.handlebars"),
      softwares: require("../../../qelpcare-call-center-application/src/templates/pages/softwares.handlebars"),
      smartphone: require("../../../qelpcare-call-center-application/src/templates/pages/smartphone.handlebars"),
      app: require("../../../qelpcare-call-center-application/src/templates/pages/app.handlebars"),
      os: require("../../../qelpcare-call-center-application/src/templates/pages/os.handlebars"),
      manufacturers: require("../../../qelpcare-call-center-application/src/templates/pages/manufacturers.handlebars"),
      device_type: require("../../../qelpcare-call-center-application/src/templates/pages/device_type.handlebars"),
      device_group: require("./templates/pages/device_group.handlebars"),
      claro_tv: require("./templates/pages/claro_tv.handlebars"),
      brand: require("../../../qelpcare-call-center-application/src/templates/pages/brand.handlebars"),
      device: require("./templates/pages/device.handlebars"),
      usecase: require("../../../qelpcare-call-center-application/src/templates/pages/usecase.handlebars"),
      categories: require("./templates/pages/categories.handlebars"),
      category: require("./templates/pages/category.handlebars"),
      tv: require("../../../qelpcare-call-center-application/src/templates/pages/tv.handlebars"),
      dts: require("../../../qelpcare-call-center-application/src/templates/pages/dts.handlebars"),
    },
    widgets: {
      breadcrumbs: require("../../../qelpcare-call-center-application/src/templates/widgets/breadcrumbs.handlebars"),
      choices: require("../../../qelpcare-call-center-application/src/templates/widgets/choices.handlebars"),
      steps_navigation: require("../../../qelpcare-call-center-application/src/templates/widgets/steps_navigation.handlebars"),
      share_topic: require("./templates/widgets/share_topic.handlebars"),
      sistemas_operacionais: require("./templates/widgets/sistemas-operacionais.handlebars"),
      side_index: require("../../../qelpcare-call-center-application/src/templates/widgets/side_index.handlebars"),
      most_viewed_devices: require("../../../qelpcare-call-center-application/src/templates/widgets/most_viewed_devices.handlebars"),
      most_viewed_apps: require("../../../qelpcare-call-center-application/src/templates/widgets/most_viewed_apps.handlebars"),
      most_viewed_usecases: require("../../../qelpcare-call-center-application/src/templates/widgets/most_viewed_usecases.handlebars"),
      most_viewed_usecases_oss: require("./templates/widgets/most_viewed_usecases_oss.handlebars"),
      device_types: require("./templates/widgets/device_types.handlebars"),
      device_groups: require("./templates/widgets/device_groups.handlebars"),
      manufacturers: require("../../../qelpcare-call-center-application/src/templates/widgets/manufacturers.handlebars"),
      manufacturer_list: require("../../../qelpcare-call-center-application/src/templates/widgets/manufacturer_list.handlebars"),
      categories: require("./templates/widgets/categories.handlebars"),
      devices_by_brand: require("../../../qelpcare-call-center-application/src/templates/widgets/devices_by_brand.handlebars"),
      specific_device: require("./templates/widgets/specific_device.handlebars"),
      dts_devices_by_brand: require("../../../qelpcare-call-center-application/src/templates/widgets/dts_devices_by_brand.handlebars"),
      dts_software_by_os: require("../../../qelpcare-call-center-application/src/templates/widgets/dts_software_by_os.handlebars"),
      decode_by_controllers: require("../../../qelpcare-call-center-application/src/templates/widgets/decode_by_controllers.handlebars"),
      software_by_os: require("../../../qelpcare-call-center-application/src/templates/widgets/software_by_os.handlebars"),
      categories_tutorials: require("./templates/widgets/categories_tutorials.handlebars"),
      categories_tutorials_app: require("../../../qelpcare-call-center-application/src/templates/widgets/categories_tutorials_app.handlebars"),
      categories_tutorials_oss: require("./templates/widgets/categories_tutorials_oss.handlebars"),
      apps: require("../../../qelpcare-call-center-application/src/templates/widgets/apps.handlebars"),
      card_filter: require("../../../qelpcare-call-center-application/src/templates/widgets/card_filter.handlebars"),
    },
  },
});
app.ready(() => {
  console.log("ready");

  app.router.add(`/sistema-operacional/(:any)`, async (os) => {
    app.loading.start();
    app.pages.sistema_operacional(os);
  });
  app.router.add(
    `/sistema-operacional/(:any)/(:any)`,
    async (os, topicPath) => {
      app.loading.start();
      app.pages.os_tutorial(os, topicPath);
    }
  );

  app.element.addEventListener("SMSSenderInitiated", (e) => {
    console.log(e);
    var SPMaskBehavior = function (val) {
      return val.replace(/\D/g, "").length === 11
        ? "(00) 00000-0000"
        : "(00) 0000-00000";
    };
    var spOptions = {
      placeholder: "(00) 0000-00000",
      onKeyPress: function (val, e, field, options) {
        field.mask(SPMaskBehavior.apply({}, arguments), options);
      },
    };
    $(".widget.share-topic .sms-sender input[name='to']").mask(
      SPMaskBehavior,
      spOptions
    );
  });
});
